import { link_youtube } from "../../services/variables";

const jsonButtons = [
  { name: `Inicio`, link: `/`, img: `Inicio` },
  { name: `Notícias`, link: `/noticias`, img: `Notícias` },
  {
    name: `Fale Conosco`,
    link: `/detalhe/fale-conosco-atendimento`,
    img: `telephone`,
  },
  {
    name: `Reuniões ao Vivo`,
    link: `https://www.instagram.com/camara.sucupiradonorte?utm_source=qr&igshid=NGExMmI2YTkyZg%3D%3D`,
    img: `camera-reels`,
  },
  {
    name: `Lei Orgânica`,
    link: `/legislativo-municipal/legislacao/lei-organica`,
    img: `lei-organica`,
  },
  {
    name: `Regimento Interno`,
    link: `/legislativo-municipal/legislacao/regimento-interno`,
    img: `regimento-interno`,
  },
  {
    name: `Informações Institucionais`,
    link: `/estrutura-organizacional`,

    img: `InstitucionalButton`,
  },
];

export default jsonButtons;
